// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  get_userGroupProfile:'',
  base_url: 'http://www.tnhighwayassets.com/tnwebservice/',
  get_all_circle: 'circles.php',
  get_all_division: 'division.php',
  get_all_sub_division: 'subDivision.php',
  get_all_section: 'section.php',
  get_all_court_case:'courtCase.php',
  get_file_upload:'files.php',
  get_all_roadcharts:'roadChart.php',
  get_all_roadLists:'roadList.php',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
