import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../../shared-module/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuuthGaurdGuard implements CanActivate {
  constructor(private router: Router, private common: CommonService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let path = route.data[0];
    this.common.toolBarTitle.next(path);
    const authToken = localStorage.getItem('tnAuthenticate');
    if (authToken) {
      return true;
    }
    this.router.navigate(['/auth/login']);
    return false;
  }





}
