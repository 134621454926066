import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuuthGaurdGuard } from './_services/gaurd/auuth-gaurd.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'main',
    loadChildren: './main-module/main-module.module#MainModuleModule',
    canActivate: [AuuthGaurdGuard]
  },
  {
    path: 'auth',
    loadChildren: './login-module/login.module#LoginModule'
  },
  {
    path: '**',
    loadChildren: './login-module/login.module#LoginModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {


}
