import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  sideNav = new BehaviorSubject(true);
  data: boolean = true;
  constructor() { }
  toolBarTitle= new BehaviorSubject('');
  sidenav(){
    this.data = !this.data;
      if(this.sideNav.observers.length > 1){
          this.sideNav.observers[0].next(this.data)
      }else {
          this.sideNav.next(this.data);
      }
  }
}
